import { lazy } from "react";
import { ACC_TKN } from "../utils/Constant";
import { Navigate, createBrowserRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { assetMgmtListRoutes, assetMgmtRoute, assetMgmtRoutes } from "./AssetManagementRoutes";
import { ecommRoute, ecommerceRoutes, ecommmerceListRoutes } from "./EcommerceRoutes";
import { hrmMutationRoutes, hrmRoute, hrmRoutes } from "./HRMRoutes";
import { masterListRoutes, masterMutationRoute, masterRoute, masterRoutes } from "./MasterRoutes";
import { mitarSCMRoutes, mitraMutationRutes, mitraSCMRoute, mitraTabRoute } from "./MitraRoutes";
import { purchMainTabRoutes, purchaseMutationRoutes, purchaseRoute, purchaseRoutes } from "./PurchaseRoutes";
import { reportListRoutes, reportRoute, reportRoutes } from "./ReportRoutes";
import { salesMainTabRoutes, salesMutationRoutes, salesRoute, salesRoutes } from "./SalesRoutes";
import { scMutationRoutes, supplyChainRoute, supplyChainRoutes, supplychainListRoutes } from "./SupplychainRoutes";
import { spalListRoutes, spalMutationRoute, spalRoute, spalRoutes } from "./SPALRoutes";
import { transListRoutes, transMutationRoutes, transRoute, transactionRoutes } from "./TransactionRoutes";
import { warehouseMutationRoutes, warehouseRoutes, warehouseTabRoutes, whsRoute } from "./WarehouseRoutes";
import { RKAMutationRoute, rkaRoute, rkaRoutes } from "./RKARoutes";
import { consignmentRouteList, consignmentRoutes } from "./ConsignmentRoutes";

//routes
export const homeRoute = { path: "/", name: "Home" };
export const loginRoute = { path: "/login", name: "Login" };
export const error_404 = { path: "/404", name: "Error 404" };
export const error_500 = { path: "/500", name: "Error 500" };
export const dashboardRoute = { path: "/dashboard", name: "menu.dashboard", icon: "dashboard" };
export const companyProfileRoute = { path: "/company_profile", name: "Profil Perusahaan" };

// component
const Login = lazy(() => import("../page/login/Login"));
const MainLayout = lazy(() => import("../layouts/MainLayout"));
const Error_404 = lazy(() => import("../page/error/Error404"));
const Error_500 = lazy(() => import("../page/error/Error500"));
const Dashboard = lazy(() => import("../page/dashboard/Dashboard"));
const CompanyProfile = lazy(() => import("../page/company_profile/CompanyProfile"));

// breadcrum
const BreadcrumbRouteList = [
  dashboardRoute,
  companyProfileRoute,
  ...[assetMgmtRoute, ...assetMgmtListRoutes],
  ...[ecommRoute, ...ecommmerceListRoutes],
  ...[hrmRoute, ...hrmMutationRoutes],
  ...[masterRoute, ...masterListRoutes, ...masterMutationRoute],
  ...[mitraSCMRoute, ...mitraTabRoute, ...mitraMutationRutes],
  ...[purchaseRoute, ...purchMainTabRoutes, ...purchaseMutationRoutes],
  ...[reportRoute, ...reportListRoutes],
  ...[salesRoute, ...salesMainTabRoutes, ...salesMutationRoutes],
  ...[spalRoute, ...spalListRoutes, ...spalMutationRoute],
  ...[supplyChainRoute, ...supplychainListRoutes, ...scMutationRoutes],
  ...[transRoute, ...transListRoutes, ...transMutationRoutes],
  ...[whsRoute, ...warehouseTabRoutes, ...warehouseMutationRoutes],
  ...[rkaRoute, ...RKAMutationRoute],
  // ...[...consignmentRouteList]
];

// main
const routes = createBrowserRouter(
  [
    { ...loginRoute, element: <Login /> },
    { ...error_404, element: <Error_404 /> },
    { ...error_500, element: <Error_500 /> },
    {
      element: <RequireAuth component={<MainLayout />} />,
      children: [
        { path: "*", element: <Navigate to={error_404.path} replace />, },
        { ...assetMgmtRoute, element: <Navigate to={dashboardRoute.path} replace /> },
        { ...dashboardRoute, element: <Dashboard /> },
        { ...companyProfileRoute, element: <CompanyProfile /> },
        { ...ecommRoute, element: <Navigate to={dashboardRoute.path} replace /> },
        { ...homeRoute, element: <Navigate to={dashboardRoute.path} replace /> },
        { ...hrmRoute, element: <Navigate to={dashboardRoute.path} replace /> },
        { ...masterRoute, element: <Navigate to={dashboardRoute.path} replace /> },
        { ...mitraSCMRoute, element: <Navigate to={dashboardRoute.path} replace /> },
        { ...purchaseRoute, element: <Navigate to={dashboardRoute.path} replace /> },
        { ...reportRoute, element: <Navigate to={dashboardRoute.path} replace /> },
        { ...salesRoute, element: <Navigate to={dashboardRoute.path} replace /> },
        { ...spalRoute, element: <Navigate to={dashboardRoute.path} replace /> },
        { ...supplyChainRoute, element: <Navigate to={dashboardRoute.path} replace /> },
        { ...transRoute, element: <Navigate to={dashboardRoute.path} replace /> },
        { ...whsRoute, element: <Navigate to={dashboardRoute.path} replace /> },
        assetMgmtRoutes,
        ecommerceRoutes,
        ...hrmRoutes,
        masterRoutes,
        ...mitarSCMRoutes,
        purchaseRoutes,
        reportRoutes,
        salesRoutes,
        spalRoutes,
        supplyChainRoutes,
        transactionRoutes,
        warehouseRoutes,
        rkaRoutes,
        // consignmentRoutes
      ],
    },
  ],
  { future: { v7_normalizeFormMethod: true } }
);

export default routes;
export { BreadcrumbRouteList };
// end main

// function list
function RequireAuth({ component }) {
  // for route that require authentication to access
  const token = Cookies.get(ACC_TKN);
  const path = window.location.pathname;
  if ([undefined, null, ""].includes(token)) return <Navigate to="/login" state={{ prev: path }} replace />;
  return component;
}
// end function list
